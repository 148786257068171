<template>
  <div class="stat">
    <h3>已用挑战 {{ totalDone }} / 90 | 待出补时 {{ totalCarryover }}</h3>
    <p>
      <el-date-picker
        v-model="selectDate"
        @change="requestData"
        type="date"
        placeholder="选择日期"
      />
    </p>
    <div style="width: 90%; max-width: 60em">
      <el-table
        :data="statDaily"
        stripe
        border
        style="width: 100%"
        cell-style="padding: 0"
        header-cell-style="padding: 0; height: 4em;"
      >
        <el-table-column type="expand" label="展开">
          <template #default="props">
            <el-table
              :data="props.row.challenges"
              border
              size="medium"
              style="width: 100%"
              cell-style="padding: 0"
              header-cell-style="padding: 0; height: 1em;"
            >
              <el-table-column
                prop="time"
                label="记录时间"
                align="center"
                sortable
                min-width="10"
                v-if="windowWidth > 650"
              />
              <el-table-column
                prop="round"
                label="周目"
                align="center"
                min-width="4"
              />
              <el-table-column
                prop="boss"
                label="王"
                align="center"
                min-width="4"
              />
              <el-table-column
                prop="dmg"
                label="伤害"
                align="right"
                min-width="10"
              />
              <el-table-column
                prop="flag"
                label="类型"
                align="center"
                min-width="6"
              />
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          prop="uid"
          label="头像"
          align="center"
          sortable
          width="61"
        >
          <template #default="scope">
            <div style="height: 50px; padding: 5px 0">
              <el-avatar
                :src="`http://q1.qlogo.cn/g?b=qq&nk=${scope.row.uid}&s=140`"
                :alt="scope.row.uid"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="昵称"
          align="center"
          min-width="6"
          v-if="windowWidth > 320"
        />
        <el-table-column
          prop="done"
          label="已用挑战"
          align="center"
          sortable
          min-width="4"
        />
        <el-table-column
          prop="carryover"
          label="待出补时"
          align="center"
          sortable
          min-width="4"
        />
      </el-table>
    </div>
  </div>
</template>

<script>
import DataService from '@/services/DataService'

export default {
  data() {
    return {
      selectDate: null,
      totalDone: 0,
      totalCarryover: 0,
      statDaily: [],
      windowWidth: window.innerWidth,
    }
  },
  created() {
    this.$emit('changeGid', this.$route.params.gid)
    this.requestData()
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.onWindowResize)
  },
  methods: {
    onWindowResize() {
      this.windowWidth = window.innerWidth
    },
    requestData() {
      let dateText
      if (this.selectDate === null) {
        dateText = this.initDateText()
      } else {
        dateText = this.formatDate(this.selectDate)
      }
      DataService.getStatDaily(this.$route.params.gid, dateText)
        .then((resp) => {
          this.statDaily = resp.data.stat
          this.totalDone = resp.data.total_done
          this.totalCarryover = resp.data.total_carryover
        })
        .catch((error) => {
          console.error(error)
        })
    },
    initDateText() {
      let date = new Date()
      date.setHours(date.getHours() + 3)
      let yyyy = date.getUTCFullYear()
      let mm = date.getUTCMonth() + 1
      let dd = date.getUTCDate()
      return `${yyyy}-${mm}-${dd}`
    },
    formatDate(date) {
      let yyyy = date.getFullYear()
      let mm = date.getMonth() + 1
      let dd = date.getDate()
      return `${yyyy}-${mm}-${dd}`
    },
  },
  props: ['gid'],
  emits: ['changeGid'],
}
</script>

<style scoped>
.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
